<template>
  <div class="placeAnOrder">
    <div class="report" @click="goReport">
      投诉
    </div>
    <div class="banner">
      <!-- <div class="bannerClick">
        <ul class="bannerTop">
          <li @click="goHome" style="opacity: 0">
            <van-icon name="arrow-left" class="icon"/>
          </li>
          <li @click="queryOrderList">
            <p class="look">查看订单</p>
          </li>
        </ul>
      </div> -->
      <van-swipe
        class="my-swipe"
        :autoplay="3000"
        indicator-color="white"
        style="width: 100%; min-height: 100px; font-size: 0px"
      >
        <van-swipe-item
          v-for="(item, index) in orderData.goodsMainImageUrl"
          :key="index"
        >
          <img
            v-lazy="item"
            alt=""
            class="bannerImg"
            loading="@assets/banner.png"
          />
        </van-swipe-item>
      </van-swipe>
      <!-- <img :src="orderData.goodsMainImageUrl" alt="" class="bannerImg"> -->
    </div>
    <div
      class="OrderWrapper"
      :style="{ backgroundColor: orderData.formBackground }"
    >
      <ul class="orderUlWrapper" v-if="orderData.sellingPrice !== '0.00'">
        <li class="orderList topList">
          <p class="navTitle">{{ orderData.pageName }}</p>
          <p class="navContent">
            <span class="newPrice">¥{{ orderData.sellingPrice }}</span>
            <span class="oldPrice">¥{{ orderData.originalPrice }}</span>
          </p>
        </li>
        <!-- <li class="orderList bottomList">
          <span class="payNumber">购买数量：</span>
          <van-stepper v-model="orderNumber" />
        </li> -->
      </ul>
    </div>
    <div
      class="OrderWrapper"
      :style="{ backgroundColor: orderData.formBackground }"
    >
      <van-radio-group
        @change="radioChange"
        v-model="packagesRadio"
        class="packageRadioWrapper"
        v-if="
          orderData.pagePackageShow === 1 && orderData.pagePackagePosition === 0
        "
      >
        <p class="packageTitle">每月套餐自选</p>
        <van-radio
          v-for="(item, index) in orderData.pagePackages"
          :name="item"
          :key="index"
          class="packageRadio"
          >{{ item }}</van-radio
        >
      </van-radio-group>
      <p class="formTitle">{{ orderData.formTitle }}</p>
      <div class="notice-sales" v-if="orderData.rollingOrderFlag === 1 || orderData.showSalesQuantityFlag === 1">
        <!-- 滚动 -->
        <van-notice-bar
          left-icon="volume-o"
          :scrollable="false"
          class="notice"
          v-if="orderData.rollingOrderFlag === 1"
        >
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item
              v-for="(item, index) in listContent"
              :key="index"
              class="vanItem"
              >{{ item.rollingName }} {{ item.rollingPhone }}
              {{ item.rollingOrderStatusName }}</van-swipe-item
            >
          </van-swipe>
        </van-notice-bar>
        <p class="salesQuantity" v-if="orderData.showSalesQuantityFlag === 1">销量：{{ orderData.salesQuantity }}</p>
      </div>
      <p class="Distribution">
        配送信息 <span>（云南、新疆、西藏不发货）</span>
      </p>
      <van-form>
        <van-field
          v-model="order.username"
          name="姓名"
          label="姓名："
          placeholder="请输入收件人姓名"
        />
        <van-field
          v-model="order.phone"
          name="手机"
          label="手机号："
          placeholder="请输入收件手机号"
          @input="phoneChange"
          maxlength="11"
        />

        <div v-show="visible">
          <van-field
            class="code"
            type="number"
            maxlength="4"
            v-model="order.CodeNumber"
            name="验证码"
            label="验证码："
            placeholder="请输入短信验证码"
            :rules="[{ required: true, message: '' }]"
            v-if="orderData.needVerificationCode"
          >
            <template #button>
              <van-button
                size="small"
                type="primary"
                @click="_sendSmsCode"
                v-if="btnShow"
                >发送验证码</van-button
              >
              <van-button
                size="small"
                type="primary"
                v-else
                style="width: 80px; font-size: 16px"
                >{{ authTime }}</van-button
              >
            </template>
          </van-field>
          <van-field
            v-model="order.identityCardNum"
            name="身份证号"
            label="身份证号："
            placeholder="请输入身份证号"
            v-if="orderData.identityCardFlag === 1"
            maxlength="18"
          />
          <van-field
            readonly
            clickable
            name="area"
            :value="value"
            label="城市："
            placeholder="点击选择省市区"
            @click="showArea = true"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <van-field
            v-model="order.address"
            name="地址"
            label="地址："
            placeholder="请输入详细地址"
          />
          <van-radio-group
            @change="radioChange"
            v-model="packagesRadio"
            class="packageRadioWrapper"
            v-if="
              orderData.pagePackageShow === 1 &&
                orderData.pagePackagePosition === 1
            "
          >
            <p class="packageTitle">每月套餐自选</p>
            <van-radio
              v-for="(item, index) in orderData.pagePackages"
              :name="item"
              :key="index"
              class="packageRadio"
              >{{ item }}</van-radio
            >
          </van-radio-group>
        </div>
      </van-form>
      <!-- <van-radio-group v-model="radio" v-if="orderData.sellingPrice !== '0.00'"> -->
      <van-radio-group v-model="radio" v-if="payShow">
        <van-cell-group>
          <van-cell clickable @click="radio = '1'">
            <span
              ><i class="iconfont" style="color: #09bb07; margin-right:5px"
                >&#xe600;</i
              >微信支付</span
            >
            <template #right-icon>
              <van-radio name="1" checked-color="#ee0a24" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <van-checkbox v-model="checked" shape="square" class="searchCheck"
        >我已阅读并同意<a href="javascript:;" @click.stop="agreementClick"
          >《入网协议》</a
        ><a href="javascript:;" @click.stop="noticeClick"
          >《信息收集公告》</a
        ></van-checkbox
      >
      <div class="btnWrapper">
        <van-button type="danger" class="placeBtn" @click="payOrder">{{
          orderData.formButtonText
        }}</van-button>
      </div>
      <van-radio-group
        @change="radioChange"
        v-model="packagesRadio"
        class="packageRadioWrapper"
        v-if="
          orderData.pagePackageShow === 1 && orderData.pagePackagePosition === 2
        "
      >
        <p class="packageTitle">每月套餐自选</p>
        <van-radio
          v-for="(item, index) in orderData.pagePackages"
          :name="item"
          :key="index"
          class="packageRadio"
          >{{ item }}</van-radio
        >
      </van-radio-group>
    </div>
    <div style="font-size: 0px">
      <img
        :src="item"
        alt=""
        class="placeBottomImg"
        v-for="(item, index) in orderData.goodsInfoImageUrl"
        :key="index"
      />
    </div>
    <van-dialog
      v-model="show"
      title="订单查询"
      show-cancel-button
      @confirm="confirmClick"
      @cancel="cancelClick"
    >
      <van-field v-model="phoneNumber" placeholder="请输入手机号" />
    </van-dialog>
    <!-- 弹框 -->
    <van-popup
      v-model="formshow"
      style="margin-bottom: 50px"
      position="bottom"
      v-if="orderData.xidiButtonFlag === 1"
    >
      <div
        class="OrderWrapper"
        :style="{ backgroundColor: orderData.formBackground }"
      >
        <!-- <p class="formTitle">{{ orderData.cmsWebPageXidiButtonVO.formTitle }}</p> -->
        <p class="Distribution">
          配送信息 <span>（云南、新疆、西藏不发货）</span>
        </p>
        <van-form>
          <van-field
            v-model="order.username"
            name="姓名"
            label="姓名"
            placeholder="姓名"
          />
          <van-field
            v-model="order.phone"
            name="手机"
            label="手机"
            placeholder="手机"
          />
          <van-field
            class="code"
            maxlength="6"
            v-model="order.CodeNumber"
            name="验证码"
            label="验证码"
            placeholder="请输入短信验证码"
            :rules="[{ required: true, message: '' }]"
            v-if="orderData.needVerificationCode"
          >
            <template #button>
              <van-button
                size="small"
                type="primary"
                @click="_sendSmsCode"
                v-if="btnShow"
                >发送验证码</van-button
              >
              <van-button
                size="small"
                type="primary"
                v-else
                style="width: 80px; font-size: 16px"
                >{{ authTime }}</van-button
              >
            </template>
          </van-field>
          <van-field
            v-model="order.identityCardNum"
            name="身份证号"
            label="身份证号"
            placeholder="身份证号"
            v-if="orderData.identityCardFlag === 1"
            maxlength="18"
          />
          <van-field
            readonly
            clickable
            name="area"
            :value="value"
            label="城市"
            placeholder="点击选择省市区"
            @click="showArea = true"
          />
          <van-popup v-model="showArea" position="bottom">
            <van-area
              :area-list="areaList"
              @confirm="onConfirm"
              @cancel="showArea = false"
            />
          </van-popup>
          <van-field
            v-model="order.address"
            name="地址"
            label="地址"
            placeholder="地址"
          />
        </van-form>
        <van-radio-group
          @change="radioChange"
          v-model="packagesRadio"
          class="packageRadioWrapper"
          v-if="orderData.pagePackageShow === 1"
        >
          <p class="packageTitle">每月套餐自选</p>
          <van-radio
            v-for="(item, index) in orderData.pagePackages"
            :name="item"
            :key="index"
            class="packageRadio"
            >{{ item }}</van-radio
          >
        </van-radio-group>
        <!-- <van-radio-group
          v-model="radio"
          v-if="orderData.sellingPrice !== '0.00'"
        > -->
        <van-radio-group v-model="radio" v-if="payShow">
          <van-cell-group>
            <van-cell clickable @click="radio = '1'">
              <span
                ><i class="iconfont" style="color: #09bb07; margin-right:5px"
                  >&#xe600;</i
                >微信支付</span
              >
              <template #right-icon>
                <van-radio name="1" checked-color="#ee0a24" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <van-checkbox v-model="checked" shape="square" class="searchCheck"
          >我已阅读并同意<a href="javascript:;" @click.stop="agreementClick"
            >《入网协议》</a
          ><a href="javascript:;" @click.stop="noticeClick"
            >《信息收集公告》</a
          ></van-checkbox
        >
      </div>
    </van-popup>
    <div class="kfWrapper" v-if="orderData.ccFlag === 1" @click="goKf">
<!-- <a href="https://work.weixin.qq.com/kfid/kfcf386df0997753d1a?enc_scene=ENC2erZaczBetbY8u2AmmZBo6kFQ4MGnkFkMRRFmgL6uLQjPNdLpBxYmUEGqU3NHqv3UR" target="_blank"> -->
        <img src="~assets/kf.png" alt="">
<!-- </a> -->
    </div>
    <div
      style="position: fixed; bottom: 0;width: 100%; z-index: 9999; display: flex; background-color: #f34538; align-items: center"
      v-show="orderData.xidiButtonFlag === 1"
    >
      <img
        @click="toTop"
        src="~assets/toTop.png"
        style="width: 20px; height: 20px; padding: 0 15px"
      />
      <!-- <p @click="toTop">↑</p> -->
      <van-button
        type="danger"
        @click="bottomPayOrder"
        style="width: 100%; height: 50px;font-size:17px;font-weight:bold"
        >{{ orderData.formButtonText }}</van-button
      >
    </div>
    <van-dialog v-model="kfshow" show-cancel-button style="opacity: 0">
      <iframe
        src="https://work.weixin.qq.com/kfid/kfc84391b466cfb71f4?enc_scene=ENCJxi9gJ1ptqYtuuVeCxL44PYULJn9HDC3bGwqJGwJJXaCYG9pfeCxbc3q5LqViJf4L"
        :key="showtimer"
        width="50%"
        height="50"
        frameborder="0"
        scrolling="auto"
        id="iframename"
        name="iframename"
      ></iframe>
    </van-dialog>
    <van-overlay :show="popupVisible">
      <a :href="backUrl">
        <div class="wrapper" @click.stop>
          <ul class="contentWrapper">
            <!-- <li class="contentLi">
              <img src="~assets/close.png" alt="" @click="hiddenVisible">
            </li> -->
            <li>
              <img :src="backImg" alt="" class="contentImg" />
            </li>
          </ul>
        </div>
      </a>
    </van-overlay>
    <van-popup v-model="agreementShow" position="bottom">
      <div class="agreementWrapper">
        <p class="title">中国网络通信客户服务协议</p>
        <p>
          甲方：中国网络通信客户 乙方：中国网络通信集团
        </p>
        <p>
          甲方所需的业务、办理手续以及资费标准请参见乙方的相关业务说明。
          根据《中华人民共和国合同法》、《中华人民共和国电信条例》及其他有关法律、法规的规定，甲乙双方在平等、自愿、公平、诚实、信用的基础上，就电话网络服务的有关事宜，达成协议如下：
        </p>
        <p>
          一、服务内容和资费标准
          1、乙方在其电话网络覆盖范围内，为甲方有偿提供通信服务；其中，乙方在签订有自动漫游协议的国家和地区的电信运营商网络覆盖范围内为甲方提供国际漫游服务。
          2、甲方可自主选择乙方提供的各项通信业务。
          3、乙方根据乙方计费系统数据向甲方收取相关费用，包括月基本费、通话费、短信（含彩信）费以及甲方申请的其他业务的费用。计费周期为自然月，即每月第一日至当月最后一日,由于网络设备产生话单及相关处理会有时延，可能发生每月部分话费计入下月话费中收取的情况，届时乙方将在话费详单中明确显示。乙方资费方案、计费规则、收取方式等如有调整，将提前公告。
          4、如甲方通过乙方的通信网络定制、收发与乙方有合作关系的信息服务提供商所提供的各类信息，甲方同意乙方根据信息服务提供商的授权，向甲方代为收取信息费。
        </p>
        <p>
          二、乙方的权利与义务
          1、乙方为甲方提供的通信服务应当达到国家的相关标准。
          2、乙方通过营业厅、网站及短信等方式向甲方公布并提示服务项目、服务时限、服务范围及资费标准等内容。
          3、乙方应采取下列一种或数种方式向甲方提供方便的业务办理、业务咨询和话费信息查询渠道，包括营业厅、服务热线、服务网站、短信及其他方式等。
          4、乙方有义务采取公布监督电话等形式受理甲方投诉，并在接到投诉之日起15日内答复甲方。
          5、乙方对电话原始话费数据及信息服务计费原始数据保留期限为5个月(系统产生用户话单当月起后5个月，不含当月)，对用户提出异议的话单，应保存至异议解决为止。
          6、若甲方对乙方收取的话费存有异议，乙方有责任进行调查、解释，并告知甲方核实处理的结果。
          7、乙方严格遵守国家法律法规关于用户个人信息保护、网络安全等规定。乙方收集、使用甲方个人信息，应当遵循合法、正当、必要的原则。甲方理解并同意，乙方及其关联公司可以通过业务受理系统登记、纸质返档，通过网络接收、读取并记录等方式，以提供电信服务为目的，在业务活动中收集、使用甲方提供的和甲方使用服务过程中形成的个人信息。乙方有权依法对包含甲方在内的整体用户数据进行分析并加以利用。未经甲方同意，乙方不向除乙方关联公司外的第三方提供甲方个人信息。甲方可以通过营业厅或乙方指定的其他渠道，对其个人信息进行查询、更正。乙方不得侵害甲方的通信自由和通信秘密，对甲方的客户资料负有保密义务。但根据法律法规规定，乙方有义务配合法定机关对客户通信、资料、数据的调查取证等工作。
          8、乙方应免费向甲方提供通话所在地（仅限国内）火警119、匪警110、医疗急救120、交通事故报警122等公益性电话接入服务。
          9、在甲方欠费的情况下，如甲方在约定的期限内补齐费用并申请开机的，乙方在收到甲方费用时起24个小时内为甲方恢复通信服务。
          10、乙方对甲方暂停通信服务时(以下简称停机)，对使用“先使用，后付费”缴费方式的甲方应提前通知；对使用“先预存话费，后使用”缴费方式的甲方，乙方应采用短信、电话等方式进行余额提示。
          11、乙方检修线路、设备搬迁、工程割接、网络及软件升级等可预见的原因可能影响甲方使用的，应提前72小时通知甲方，乙方应采用下列一种或数种方式，即短信、电话、广播、电视、信函、公告、报纸、互联网等方式进行业务公告或通知。
          12、甲方向乙方申告电话通信障碍（指交换设备或传输线路原因造成通信中断等现象，不包括网络覆盖和终端设备故障），乙方应按《电信条例》的规定处理。
          13、乙方应在承诺时限内为甲方开通其申请的服务(双方约定超出此时限的除外)，乙方未及时开通的，应免除甲方自申请之日至服务开通期间的该项服务的月功能费。此外造成甲方直接损失的，乙方依法承担违约责任。
          14、乙方承诺自身不利用通信网络向甲方发送违法信息或骚扰信息（不包括乙方为甲方提供业务服务而发送的信息）。
        </p>
        <p>
          三、甲方的权利与义务
          1、甲方办理入网手续时，需向乙方提供真实有效的身份证件原件，代办入网时，需同时提供代理人有效身份证件原件及授权委托书。个人客户有效证件包括居民身份证、临时居民身份证或户口簿；中国人民解放军军人、武装警察身份证件；港澳居民往来内地通行证、台湾居民往来大陆通行证或者其他有效旅行证件；外国公民护照。单位客户有效证件包括统一社会信用代码证书、营业执照副本、组织机构代码证等有效证件原件或加盖单位公章的有效证件的复印件。以单位证件办理入网登记的，除出示单位有效证件之一外，还应当出示经办人和使用人的有效证件及授权委托书。如甲方及其受托人拒绝出示有效证件，拒绝提供其证件上所记载的身份信息，乙方有权不为其办理入网手续。如甲方入网登记资料发生变更，应及时通知乙方。
          甲方应当按照约定的时间和方式及时、足额地向乙方交纳电信费用；甲方逾期不交纳电信费用的，乙方有权要求补交电信费用，并可以按照所欠费用每日加收3‰的违约金。
          3、若甲方对乙方收取的通信费及代信息服务商收取的信息费存有异议，应在异议话费发生后五个月内向乙方提出(系统产生用户话单当月起后5个月，不含当月)，乙方应将有争议的数据保存到争议解决为止。
          4、甲方要求终止提供通信服务时，应在结清所有费用后办理退网手续。
          5、甲方承诺不利用通信网络发送违法信息或对其他客户的骚扰信息，甲方向第三方发送广告等信息，需经第三方同意认可。
        </p>
        <p>
          四、特殊情况的责任承担
          1、在下列情况下，乙方有权暂停或限制甲方的通信服务，由此给甲方造成的损失，乙方不承担责任：
          （1）甲方银行帐户被查封、冻结或余额不足等非乙方原因造成乙方结算时划扣不成功的；
          （2）甲方预付费使用完毕而未及时补交款项（包括预付费账户余额不足以划扣下一笔预付费用）的；
          （3）甲方使用“先使用，后付费”的费用结算方式时，电话费用超过可透支额度的；
          （4）甲方发送带有非法内容信息的。
          2、乙方一经发现甲方突然出现超过自己此前三个月平均通信费用5倍以上通信费用时，应当尽可能迅速告知甲方，乙方可以暂停或限制甲方的通信服务，并采取相应的措施。由此给甲方造成的损失，乙方不承担责任。
          3、甲方如出现违法使用、违规外呼、呼叫频次异常、超约定用途使用、转租转售、发送违法违规信息、未经接收客户同意发送商业广告信息给他人造成骚扰、被公安机关通报以及用户就上述问题投诉较多等情况的，经乙方核实确认后，乙方有权终止业务接入。因此给甲方造成的损失，乙方不承担责任。
          4、甲方名下的电话号码逾期未交清费用的，乙方有权拒绝甲方以自己名义提出的其他通信业务申请（包括开办新的电话卡），缴费、话费查询除外。根据国家相关规定，甲方办理有效使用的电话卡达到5张的，乙方不予为其开办新的电话卡。
          5、在甲方欠费时，乙方可采取信函、委托第三方等形式追缴欠费，并可按照有关规定向征信机构提供甲方的欠费信息。
          6、甲方入网后即获取客户服务密码，甲方应妥善保管。服务密码是业务办理的凭证。凡使用服务密码定制、变更业务的行为均被视为甲方或甲方授权的行为。为方便办理业务，乙方也可根据甲方申请以短信等方式提供随机服务密码，该密码可作为办理业务的临时凭证。
          7、因甲方保管不善等原因导致其电话被他人非法使用，甲方应尽可能迅速告知乙方，办理停机或补卡手续，乙方应予配合并依法采取相应措施。甲方可向公安部门报案，乙方应在技术上协助公安部门进行调查。除乙方有过错外，乙方不承担相应责任。
          8、因甲方（包括代理人）提供的客户资料不详、不实或变更后未通知乙方等原因，使乙方无法将服务（如话费单据等）提供给甲方，乙方不承担由此对甲方所造成的不良后果。甲方（包括代理人）未完全履行本协议约定义务，所提供客户资料不真实、不准确、不完整或无效的，乙方有权暂时中止履行本协议并催告甲方（包括代理人）补充提供相关资料。甲方在接到乙方催告后30天内拒不补充提供到位的，乙方有权终止本协议。
          9、当不可抗力的情形发生时，根据不可抗力的影响，部分或者全部免除责任，但法律另有规定的除外。甲方或乙方迟延履行后发生不可抗力的，不能免除责任。
          10、一方违约给对方造成损失的，应当依法承担赔偿责任。
        </p>
        <p>
          五、协议的变更、转让与终止
          1、甲方办理各类业务所签署的表单、乙方以公告等书面形式公开做出的服务承诺均为本协议的补充协议，与本协议冲突部分以补充协议为准，补充协议中未约定部分以本协议为准。但补充协议不得规避和降低乙方的法定义务和服务标准。同时不一致条款应向客户明示（如字体加黑、划线或变换字体等）。
          2、乙方承诺资费方案有效期为一年（双方有特殊约定的除外）。乙方有权在有效期截止后修改资费方案。如需修改，乙方应在有效期届满前两个月通知甲方。如无需修改，则原资费方案顺延一年，顺延次数不限。如甲方不接受修改，则甲方可到期取消该资费方案。
          3、如甲方要将协议项下的手机号码及附属权利义务转让给第三人（过户），应与第三人共同到乙方营业网点协商，在三方就权利义务转移等问题达成一致后，共同签订过户协议，本协议同时自动终止。因甲方私自转让而造成的欠费、停机等后果，由甲方自行承担。
          4、因技术进步，乙方为提升服务质量对电话网络进行整体换代升级而导致通信服务无法继续履行的，乙方应至少提前90日以短信或者公告的方式告知甲方，并提供合理的解决方案。
          5、下列情况下乙方有权解除协议，收回号码，终止提供服务。由此给甲方造成的损失，乙方不承担责任，并有权向甲方追讨欠费：
          （1）电话被用于违法犯罪活动，或甲方严重违约致使乙方遭受较大损失；
          （2）乙方收到法定机关要求停止为甲方提供通信服务；
          （3）甲方欠费停机超过60天。
          6、在法定终止条件或约定终止条件具备时，本协议终止。乙方继续保留向甲方追缴所欠费用的权利，有权收回号码，并在服务规范规定的冷号期后重新启用。
        </p>
        <p>
          六、其他约定
          1、信息服务提供商通过乙方的网络平台提供的增值电信应用服务，甲方可自愿订购或退订。
          2、甲方使用信息服务提供商提供的增值电信应用服务的资费标准由信息服务提供商公布。若甲方对收取的服务费用有异议，乙方与信息服务提供商应遵循“首问负责”的原则，共同协商处理。
          3、乙方关联公司是指乙方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的合法继承人。其中“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
          4、双方的联系方式位于签章处，一方如有变更，应及时通知另一方，未及时通知的，一方按照原有地址发送文件的（包括但不限于欠费催缴通知）发出后即视为送达。
          5、本协议项下发生的争议，双方可协商解决，协商不成的，双方可选择向电信管理部门申诉，或向消费者协会投诉，或按下列方式解决： 向本地人民法院提起诉讼。
          6、本协议双方签字、盖章后生效。对本协议未尽事宜，双方协商解决。
        </p>
        <p>甲方 ：（签名或单位盖章） 乙方：（合同专用章）</p>
        <p>
          甲方监护人或代理人：（签名或单位盖章）
          地址：_________________________________
        </p>
        <p>地址：___________________________</p>
        <p>甲方号码：___________________</p>
        <p>签署日期：________年____月____日</p>
      </div>
    </van-popup>
    <van-popup v-model="noticeShow" position="bottom">
      <div class="agreementWrapper">
        <p class="title">信息收集公告</p>
        <p>尊敬的客户：</p>
        <p>
          根据《中华人民共和国反恐怖主义法》、《全国人民代表大会常务委员会关于加强网络信息保护的决定》、《电信和互联网用户个人信息保护规定》（工业和信息化部令第24号）和《电话用户真实身份信息登记规定》（工业和信息化部令第25号）等国家法律法规的要求，客户在各类营业网点（含自有营业厅、手机营业厅、网上营业厅、授权合作代理商等）办理固定电话、电话（含无线上网卡）入网、过户以及需要出示客户证件的有关业务时，客户应配合出示有效证件原件并进行查验、登记，登记信息包括姓名、证件类型、号码及地址等。同时，为更好地提供服务，需要客户提供如联系人、联系电话、通信地址、电子邮箱等信息。客户本人持有效证件可通过自有营业厅查询和/或更正本人信息，或登录手机营业厅查询本人信息。
        </p>
        <p>
          如客户拒绝依法提供个人有效证件及真实信息，我公司有权不提供服务或终止服务。
        </p>
        <p>
          为向客户提供优质、个性化的服务，包括但不限于提供通信服务、保障通信服务安全、改善服务质量、推介个性化产品等，我公司将遵循合法、正当、必要的原则，按照法律法规规定和/或协议约定使用留存客户个人信息，并妥善保管，严格保密，依法保护客户个人信息，非因下列事由，不对外泄露客户个人信息：
        </p>
        <p>(a)事先获得客户的明确授权；</p>
        <p>(b)根据有关的法律法规要求；</p>
        <p>(c)按照相关司法机关和/或政府主管部门的要求；</p>
        <p>(d)为维护社会公众的利益所必需且适当；</p>
        <p>(e)为维护我公司或客户的合法权益所必需且适当。</p>
        <!-- <p>中国联合网络通信有限公司</p> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  getWebPage,
  postPayOrder,
  getAreaList,
  getNumberCode,
  postBottomPayOrder,
  getRolling,
  getCustomerServiceInfo
} from "api/api";
export default {
  name: "placeAnOrder",
  data() {
    return {
      orderNumber: 1,
      radio: "1",
      showArea: false,
      value: "",
      areaList: [], //城市列表数据
      // 表单数据
      order: {
        username: "",
        phone: "",
        address: "",
        CodeNumber: "",
        identityCardNum: "",
      },
      cityCode: "",
      orderData: "", //页面显示数据
      checked: false,
      listContent: [], //滚动条数据
      show: false,
      formshow: false,
      identityCardFlag: 0,
      phoneNumber: "",
      authTime: 60,
      btnShow: true,
      kfshow: false,
      showtimer: "",
      visible: true,
      backVisible: false,
      backUrl: "",
      backImg: "",
      popupVisible: false,
      agreementShow: false,
      noticeShow: false,
      packagesRadio: null,
      biliStatus: false,
      payShow: false
    };
  },
  created() {
    this.$store.commit(
      "setUrl",
      window.location.href.split("/placeAnOrder")[0]
    );
    this._getAreaList(this.$route.params);
    this.$store.commit("setOrderId", this.$route.params.id);
    this._getData(this.$route.params);
    // if (window.location.href.indexOf('&clickid=') > 0) {
    //   if (window.location.href.split('&clickid=')[1].indexOf('&') > 0) {
    //     this.$store.commit('setClickId', window.location.href.split('&clickid=')[1].split('&')[0])
    //   } else {
    //     this.$store.commit('setClickId', window.location.href.split('&clickid=')[1])
    //   }
    // } else if (window.location.href.indexOf('?') > 0) {
    //   this.$store.commit('setClickId', window.location.href.split('?')[1])
    // }
    if (this.$route.params.id === '11374') {
      this.$router.push({
        name: "404",
      });
    }
    this.$store.commit("setClickId", window.location.href);
    if (window.location.href.indexOf("__BZ__") > 0) {
      this.biliStatus = true;
    } else {
      this.biliStatus = false;
    }
  },
  //1、挂载完成后，判断浏览器是否支持popstate
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.goBack, false);
    }
  },
  //页面销毁时，取消监听。否则其他vue路由页面也会被监听
  destroyed() {
    // window.removeEventListener('popstate', this.goBack, false);
  },
  methods: {
    goReport() {
      this.$router.push({
        name: "report",
      });
    },
    onConfirm(values) {
      this.cityCode = values[values.length - 1].code;
      console.log(this.cityCode);
      this.value = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    // 获取页面数据
    _getData(params) {
      getWebPage({ id: params.id }).then((res) => {
        if (res && res.code === 200) {
          this.orderData = res.result;
          document.title = res.result.pageName;
          if (res.result.productCode.startsWith("BZ_")) {
            this.biliStatus = true
          } else {
            this.biliStatus = false
          }
          if (res.result.formFoldingFlag === 1) {
            this.visible = false;
          }
          if (res.result.returnPopupFlag === 1) {
            this.backVisible = true;
            this.backUrl = res.result.returnPopupUrl;
            this.backImg = res.result.returnPopupImg;
          }
          if (
            res.result.rollingOrderFlag &&
            res.result.rollingOrderFlag === 1
          ) {
            this._getRolling();
          }
          eval(res.result.headCode);
          /* if (this.biliStatus) {
            window.bilicm.mbase.report_data();
          } */
          if (res.result.transformCodeExecuteTiming === 3) {
            eval(res.result.transformCode);
          }
          if (res.result.codeType === 0) {
            // 头部代码
            eval(res.result.headCode);
          } else if (res.result.codeType === 1) {
            // 转换代码
            eval(res.result.transformCode);
          }
        } else {
          this.$router.push({
            name: "404",
          });
        }
      });
    },
    // 获取地址列表
    _getAreaList(argument) {
      let params = {
        productCode: argument.id,
      };
      getAreaList(params).then((res) => {
        if (res && res.code === 200) {
          this.areaList = res.result;
        } else {
          this.$toast(res.message);
        }
      });
    },
    // 滚动订单信息
    _getRolling() {
      getRolling().then((res) => {
        console.log("滚动订单", res);
        if (res && res.code === 200) {
          // this.listContent.push(res.result[0])
          this.listContent = res.result;
        }
      });
    },
    // 返回首页
    goHome() {
      this.$router.replace({
        path: `/home/${this.$store.state.userId}`,
      });
    },
    // 获取短信验证码
    _sendSmsCode() {
      getNumberCode(this.order.phone).then((res) => {
        this.$toast(res.message);
        this.startTime();
        this.btnShow = false;
      });
    },
    startTime() {
      this.timer = setInterval(() => {
        this.authTime--;
        if (this.authTime <= 0) {
          this.clearTime();
          this.btnShow = true;
        }
      }, 1000);
    },
    clearTime() {
      if (this.timer) {
        this.authTime = 60;
        clearInterval(this.timer);
      }
    },
    // 查看订单
    queryOrderList() {
      this.show = true;
    },
    // 订单查询按钮 确认
    confirmClick() {
      if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
        return this.$toast("请输入正确的手机号");
      }
      this.$router.push({
        name: "order",
        params: {
          phone: this.phoneNumber,
        },
      });
    },
    // 订单查询按钮 取消
    cancelClick() {
      this.show = false;
    },
    // 提交订单
    payOrder(argument) {
      if (!this.order.username) {
        return this.$toast("请输入姓名");
      }
      if (!/^1[3456789]\d{9}$/.test(this.order.phone)) {
        return this.$toast("请输入正确的11位手机号");
      }
      if (!this.order.address) {
        return this.$toast("请输入详细地址");
      }
      if (!this.value) {
        return this.$toast("请选择地区");
      }
      if (this.orderData.identityCardFlag == 1) {
        if (!this.checkIdCard()) {
          return this.$toast("请输入正确的身份证号码");
        }
      }
      if (this.checked) {
        this._postPurchase(argument);
        if (this.orderData.transformCodeExecuteTiming === 2) {
        //   eval(this.orderData.transformCodeExecuteRate)
        //   eval(this.orderData.transformCode)
          // if (this.biliStatus) {
          //   window.bilicm.mbase.report_data()
          // }
        }
      } else {
        this.$toast("请先同意协议");
      }
    },
    checkIdCard() {
      var code = this.order.identityCardNum;
      var city = {
        11: "北京",
        12: "天津",
        13: "河北",
        14: "山西",
        15: "内蒙古",
        21: "辽宁",
        22: "吉林",
        23: "黑龙江 ",
        31: "上海",
        32: "江苏",
        33: "浙江",
        34: "安徽",
        35: "福建",
        36: "江西",
        37: "山东",
        41: "河南",
        42: "湖北 ",
        43: "湖南",
        44: "广东",
        45: "广西",
        46: "海南",
        50: "重庆",
        51: "四川",
        52: "贵州",
        53: "云南",
        54: "西藏 ",
        61: "陕西",
        62: "甘肃",
        63: "青海",
        64: "宁夏",
        65: "新疆",
        71: "台湾",
        81: "香港",
        82: "澳门",
        91: "国外 ",
      };
      var tip = "";
      var pass = true;
      if (
        !code ||
        !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|[xX])$/.test(
          code
        )
      ) {
        tip = "身份证号格式错误";
        pass = false;
      } else if (!city[code.substr(0, 2)]) {
        tip = "地址编码错误";
        pass = false;
      } else {
        //18位身份证需要验证最后一位校验位
        if (code.length == 18) {
          code = code.split("");
          //∑(ai×Wi)(mod 11)
          //加权因子
          var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
          //校验位
          var parity = [1, 0, "X", 9, 8, 7, 6, 5, 4, 3, 2];
          var sum = 0;
          var ai = 0;
          var wi = 0;
          for (var i = 0; i < 17; i++) {
            ai = code[i];
            wi = factor[i];
            sum += ai * wi;
          }
          var last = parity[sum % 11];
          if (parity[sum % 11] != code[17]) {
            tip = "校验位错误";
            pass = false;
          }
        }
      }
      console.log("身份证号", tip);
      return pass;
    },
    bottomPayOrder() {
      if (this.formshow) {
        this.payOrder("xidi");
      } else {
        this.formshow = true;
      }
    },
    // 协议
    agreementClick() {
      console.log("协议");
      this.agreementShow = true;
    },
    noticeClick() {
      console.log("公告");
      this.noticeShow = true;
    },
    radioChange(val) {
      // console.log('change', val)
      // console.log('c777777', this.packagesRadio)
    },
    // 支付
    _postPurchase(argument) {
      let params = {
        deliveryAddress: this.order.address, //收货详细地址
        deliveryAreaCode: this.cityCode, //收货地编号
        deliveryMobile: this.order.phone, //联系电话
        deliveryName: this.order.username, //联系人
        identityCardNum: this.order.identityCardNum, //身份证号
        pageId: this.$route.params.id, //网页id
        verificationCode: this.order.CodeNumber,
        packageName: this.packagesRadio,
      };
      if (this.orderData.pagePackageShow === 1 && this.packagesRadio === null) {
        this.$toast("请选择意向套餐");
        return;
      }
      if (argument === "xidi") {
        postBottomPayOrder(params).then((res) => {
          if (res && res.code === 200) {
            console.log("结果", res);
            if (this.orderData.transformCodeExecuteTiming === 1) {
              eval(this.orderData.transformCode);
              if (this.biliStatus) {
                window.bilicm.mbase.report_data();
              }
            }
            if (this.orderData.transformCodeExecuteTiming === 2) {
              eval(this.orderData.transformCodeExecuteRate);
              eval(this.orderData.transformCode);
              if (this.biliStatus) {
                window.bilicm.mbase.report_data();
              }
            }
            if (res.result.needPay) {
              // 跳转支付结果页面
              let Url = `${this.$store.state.localhostUrl}/result/${this.$route.params.orderNo}`;
              // let redircetUrl = encodeURIComponent(Url)
              // window.location.href = res.result.payUrl + `&redirect_url=${redircetUrl}`
              // 跳转订单详情页
              // let Url = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`
              let redircetUrl = encodeURIComponent(Url);
              setTimeout(() => {
                window.location.href =
                  res.result.payUrl + `&redirect_url=${redircetUrl}`;
              }, false);
              // window.location.href = res.result.payUrl + `&redirect_url=${redircetUrl}`
            } else {
              // window.location.href = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`
              setTimeout(() => {
                window.location.href = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`;
              }, false);
            }
          } else {
            this.$toast(res.message);
          }
        });
      } else {
        postPayOrder(params).then((res) => {
          if (res && res.code === 200) {
            console.log("结果", res);
            if (this.orderData.transformCodeExecuteTiming === 1) {
              eval(this.orderData.transformCode);
              if (this.biliStatus) {
                window.bilicm.mbase.report_data();
              }
            }
            if (this.orderData.transformCodeExecuteTiming === 2) {
              eval(this.orderData.transformCodeExecuteRate);
              eval(this.orderData.transformCode);
              if (this.biliStatus) {
                window.bilicm.mbase.report_data();
              }
            }
            if (res.result.needPay) {
              // 跳转支付结果页面
              let Url = `${this.$store.state.localhostUrl}/result/${res.result.orderNo}`;
              // let redircetUrl = encodeURIComponent(Url)
              // window.location.href = res.result.payUrl + `&redirect_url=${redircetUrl}`
              // 跳转订单详情页
              // let Url = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`
              let redircetUrl = encodeURIComponent(Url);
              // window.location.href = res.result.payUrl + `&redirect_url=${redircetUrl}`
              setTimeout(() => {
                window.location.href =
                  res.result.payUrl + `&redirect_url=${redircetUrl}`;
              }, false);
            } else {
              // window.location.href = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`
              setTimeout(() => {
                window.location.href = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`;
              }, false);
            }
          } else {
            this.$toast(res.message);
          }
        });
      }
    },
    goKf() {
      getCustomerServiceInfo(this.orderData.userId).then(res => {
        if (res && res.result.appletJumpUrl) {
          window.location.href = res.result.appletJumpUrl
        } else {
          var u = navigator.userAgent;
          var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
          // window.location.href = "https://work.weixin.qq.com/kfid/kfc84391b466cfb71f4?enc_scene=ENCJxi9gJ1ptqYtuuVeCxL44PYULJn9HDC3bGwqJGwJJXaCYG9pfeCxbc3q5LqViJf4L";

          if (isAndroid) {
            this.kfshow = true;
            this.showtimer = new Date().getTime();
            setTimeout(() => {
              this.kfshow = false;
            }, 500);
          } else if (isiOS) {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("like mac os x") > 0) {
              var reg = /os [\d._]*/gi;
              var verinfo = ua.match(reg);
              var version = (verinfo + "").replace(/[^0-9|_.]/gi, "").replace(/_/gi, ".");
              var arr = version.split(".");
              // this.ios = arr
              if (arr[0] > 12) {
                //对12.1以后的版本处理,不包括13.0,
                window.location.href =
                  "https://work.weixin.qq.com/kfid/kfc84391b466cfb71f4?enc_scene=ENCJxi9gJ1ptqYtuuVeCxL44PYULJn9HDC3bGwqJGwJJXaCYG9pfeCxbc3q5LqViJf4L";
              } else {
                this.kfshow = true;
                this.showtimer = new Date().getTime();
                setTimeout(() => {
                  this.kfshow = false;
                }, 500);
              }
            }
          }
        }
      })
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          document.body.scrollTop = document.documentElement.scrollTop = top = 0;
          clearInterval(timeTop);
        }
      }, 10);
    },
    phoneChange(val) {
      if (val.length === 11) {
        this.visible = true;
      }
    },
    goBack() {
      if (this.backVisible) {
        this.popupVisible = true;
      }
      //replace替换原路由，作用是避免回退死循环
    },
    hiddenVisible() {
      this.popupVisible = false;
    },
  },
};
</script>

<style lang="stylus" scoped>
.placeAnOrder
  min-height 100%
  .banner
    // height 375px
    position relative
    .bannerImg
      width 100%
      height 100%
    .bannerClick
      width 100%
      position absolute
      top 15px
      // z-index 999
      .bannerTop
        display flex
        justify-content space-between
        .icon
          margin-left 15px
          color #fff
          background-color #000
          padding 9px
          border-radius 50%
        .look
          margin-right 15px
          color #fff
          padding 5px 15px
          background-color #000
          border-radius 11px
          font-size 13px
  .OrderWrapper
    background-color #f4be9c
    padding 2px 9px 10px 9px
    .Distribution
      font-size 14px
      font-weight bold
      padding 20px 10px
      background-color #FFF
      clear both
      span
        color #ee394e
    .orderUlWrapper
      background-color #fff
      border-radius 4px
      .topList
        border-bottom 1px solid #F7F7F7
      .orderList
        padding 18px 20px 20px 12px
        .navTitle
          font-size 18px
          font-weight bold
          color #333
        .navContent
          padding-top 20px
          .newPrice
            font-size 16px
            font-weight bold
            color #FA5757
            padding-right 11px
          .oldPrice
            font-size 12px
            font-weight 500
            color #999
            text-decoration line-through
      .bottomList
        display flex
        justify-content space-between
        .payNumber
          font-size 13px
          font-weight 500
          color #333
  .OrderWrapperBottom
    background-color #191D3D
    margin 10px 8px 10px 8px
  .searchCheck
    background-color #FFF
    padding 20px 10px 18px 10px
    font-size 12px
    color #333
    font-weight 500
  >>> .van-checkbox__icon
    font-size 15px
  .btnWrapper
    display flex
    padding 0px 3% 16px 3%
    background-color #FFF
    border-radius 0 0 6px 6px
    .placeBtn
      flex 1
      border-radius 23px
      background #d9571d
      font-size 17px
      font-weight bold
      border none
  .placeBottomImg
    width 100%
    height 100%
.formTitle
  padding-top 15px
  font-size 19px
  font-weight bold
  text-align center
  background-color #FFF
  border-radius 6px 6px 0 0
.notice-sales
  width 100%
  height 40px
  background-color #fff
  >>> .van-notice-bar {
    padding-right 0
  }
  .notice
    width calc(100% - 130px)
    box-sizing border-box
    float: left;
    background-color #fff
  .salesQuantity
    float right
.salesQuantity
  width 120px
  box-sizing border-box
  background-color #FFF
  font-size 14px
  color #999
  text-align right
  padding-right 20px
  height 40px
  line-height 40px

.code
  line-height 32px
>>> .van-cell
  padding 13px
  padding-left 11px
  font-size 16px
>>> .van-cell__title
  color #333333
  font-weight bold
>>> .van-field__label
  width 5.2em
.kfWrapper
  position fixed
  right 3%
  bottom 5%
  width 60px
  height 60px
  img
    width 100%
    height 100%
.wrapper
  width 100%
  height 100%
  display flex
  align-items center
  .contentWrapper
    width 100%
    display flex
    flex-direction column
    align-items center
    .contentLi
      display flex
      flex-direction row-reverse
      width 100%
      padding 0 30% 10px 0
    .contentImg
      width 270px
      height 243px
.notice-swipe
  height 40px
  line-height 40px
.agreementWrapper
  height 300px
  overflow scroll
  background-color #fff
  text-indent 28px
  line-height 25px
  padding 10px 10px
  p
    font-size 12px
  .title
    text-indent 0
    text-align center
    font-size 16px
    font-weight bold
    margin 20px 0
.packageRadioWrapper
  background-color #fff
  padding 10px 20px 0 20px
  border-bottom 1px solid #ebedf0
  .packageRadio
    padding 0 0 20px 20px
    font-weight 500
  .packageTitle
    font-size 16px
    font-weight bold
    padding-bottom 20px
.report
  width 40px
  height 30px
  line-height 30px
  background rgba(0, 0, 0, 0.6)
  color #dad6d6
  position fixed
  top 20px
  right 0px
  padding-left 20px
  border-radius 20px 0 0 20px
  font-size 15px
  z-index 999
>>> .van-field__control
  margin-left -14px
  font-size 15px
>>> .van-field__button
  margin-top -6px
>>> .van-button--primary
  background-color #ee394e
  border none
</style>
